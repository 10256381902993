import { axiosInstance } from "../../api/config";

export const getOnlineAppointments = async (mode = 'New') => {
    try {
        let url = `/pharmacycart/new_pharmacycart`
        if (mode === "Completed") {
            url = `/pharmacycart/order_completed`
        }
        const response = await axiosInstance.get(url);
        return response;
    } catch (error) {
        throw error;
    }
}

export const updateOnlineAppointmentStatus = async (status, appointmetnId) => {
    try {
        const requestData =  { status };
        const requestConfig = {
            headers: { "Content-Type": "application/json" },
        };
        const response = await axiosInstance.put(`/pharmacycart/update_pharmacycart/${appointmetnId}`, requestData, requestConfig);
        return response;
    } catch (error) {
        throw error;
    }
}
