import React from 'react'
import { MedicinesMainSection } from '../../components/MedicinesComponents'

const Tests = () => {
  return (
    <div className="w-full h-fit  px-2 pt-10 sm:p-10 relative">
      <MedicinesMainSection />
    </div>
  );
}

export default Tests