import { axiosAuthorized } from "../../api/config";

export const getPrescriptions = async (pharmacyId, mode='Today') => {
    try {
      let url = `/prescription/get_prescription`
        if (mode === `Completed`) {
          url = `/prescription/get_prescription`
        } else if (mode === `New`) {
          url = `/prescription/get_prescription`
        }
        const response = await axiosAuthorized.get(url);
        return response;
    } catch (error) {
        throw error;
    }
}

export const updateStatus = async (status, appointmetnId) => {
    try {
        const requestData =  { status };
        const requestConfig = {
            headers: { "Content-Type": "application/json" },
        };
      const response = await axiosAuthorized.put(`/prescription/update_prescription/${appointmetnId}`, requestData, requestConfig);
        return response;
    } catch (error) {
        throw error;
    }
}
