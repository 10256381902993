import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    pharmacy: null,
};

const userSlice = createSlice({
    name: "userSlice",
    initialState,
    reducers: {
        saveUser: (state, action) => {
            state.pharmacy = action.payload;
        },
        logout: (state) => {
            localStorage.removeItem("accessToken");
            localStorage.removeItem("pharmacy");
            state.pharmacy = null;
        },
    },
});

export default userSlice.reducer;
export const { saveUser, logout } = userSlice.actions;