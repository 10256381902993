import React, { useCallback, useEffect, useState } from 'react'
import "./DashboardCard.css"
import { AppointmentCard,TotalPatientCard,MessageConsulting } from './DashbhoardCardSvg';
import { getMedicinesCount } from '../../../utils/medicinesUtils/medicineUtils';
import { getAppointmentsCount } from '../../../utils/appointmentsUtils/appointmentsUtils';
import { getCategoriesCount } from '../../../utils/categoriesUtils/categoriesUtils';

const DashboardCards = () => {
    const [medicines, setMedicines] = useState(0);
  const [appointments, setAppointments] = useState(0);
  const [categories, setCategories] = useState(0);
  const setMedicinesFn = useCallback(() => {
    getMedicinesCount()
      .then((res) => {setMedicines(res?.data?.data?.medicine_count || 0)})
      .catch((err) => console.error(err));
  },[])
  const setAppointmentsFn = useCallback(() => {
    getAppointmentsCount()
      .then((res) =>{ setAppointments(res?.data?.data?.pharmacy_appointment_count || 0)})
      .catch((err) => console.error(err));
  }, []);
  const setCategoriesFn = useCallback(() => {
    getCategoriesCount()
      .then((res) => { setCategories(res?.data?.data?.medicine_category_count || 0)})
      .catch((err) => console.error(err));
  },[])
  useEffect(() => {
    setMedicinesFn();
    setCategoriesFn();
    setAppointmentsFn();
  }, [setMedicinesFn, setCategoriesFn, setAppointmentsFn]);
  return (
    <div className="dashboardcard-container">
      <AppointmentCard count={medicines}/>
      <TotalPatientCard count={appointments}/>
      <MessageConsulting count={categories}/>
    </div>
  );
}

export default DashboardCards