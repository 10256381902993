import React, { useCallback, useEffect, useState } from 'react'
import { NoDataFound, SearchInput } from '../Common';
import { AiFillMedicineBox } from "react-icons/ai";
import MedicineTable from './MedicineTable';
import MedicineModal from './MedicineModal';
import { getMedicines } from '../../utils/medicinesUtils/medicineUtils';
import { useSelector } from 'react-redux';

const MedicinesMainSection = () => {
    const user = useSelector((state) => state.userReducer.pharmacy);
    const [open, setOpen] = useState(false);
    const [editData,setEditData] = useState(null);
    const [medicines, setMedicines] = useState([]);
    const setMedicinesFn = useCallback(() =>{
      getMedicines(user?._id).then(res => { setMedicines(res.data.data) }).catch(err => console.log(err));
    }, [user?._id]);
  useEffect(() => { setMedicinesFn() }, [setMedicinesFn]);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  useEffect(() => {
    const filteredData = medicines?.filter((data) => {
      const nameMatch = data?.med_name
        ?.toLowerCase()
        ?.includes(searchQuery?.toLowerCase());
      const categoryMatch = data?.category?.pharmacy_category_name
        ?.toLowerCase()
        ?.includes(searchQuery?.toLowerCase());
      return nameMatch || categoryMatch;
    });
    setFilteredData(filteredData);
  }, [medicines, searchQuery]);
  return (
    <>
      {open && (
        <MedicineModal
          setEditData={setEditData}
          setOpen={setOpen}
          setMedicinesFn={setMedicinesFn}
          editData={editData}
          user={user}
        />
      )}
      <div className="flex justify-between items-center">
        <div className="flex font-semibold text-lg">Medicines</div>
        <div className="flex">
          <div className="flex">
            <SearchInput
              placeholder={"Search by Name, Category"}
              searchQuery={searchQuery}
              setSearchQuery={setSearchQuery}
            />
          </div>
        </div>
      </div>
      <div className="flex  w-full h-full mt-5 justify-between items-center ">
        <div className="flex font-semibold gap-3 text-primary">Medicines</div>
        <div className="flex gap-2">
          <button
            className="bg-secondary text-white   text-sm rounded-md flex gap-2 p-2 active:bg-secondary items-center"
            onClick={() => {
              setOpen(true);
            }}
          >
            <span>
              <AiFillMedicineBox />
            </span>
            <span className="flex gap-1">Medicine</span>
          </button>
        </div>
      </div>
      {filteredData?.length > 0 ? (
        <MedicineTable
          datas={filteredData}
          setEditData={setEditData}
          setOpen={setOpen}
          setMedicinesFn={setMedicinesFn}
        />
      ) : (
        <div className="flex w-full justify-center items-center mt-10">
          <NoDataFound data={"medicines"} />
        </div>
      )}
    </>
  );
}

export default MedicinesMainSection