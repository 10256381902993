import React, { useMemo } from "react";
import { MdOutlineDeleteOutline } from "react-icons/md";
import { Table } from "../Common";
import { TbUserEdit } from "react-icons/tb";
import { deleteMedicine } from "../../utils/medicinesUtils/medicineUtils";

const MedicinesTable = ({ datas, setOpen, setEditData, setMedicinesFn }) => {
  const headings = useMemo(
    () => [
      "Medicine",
      "Category",
      "Quantity",
      "M-Date",
      "Price",
      "Discount",
      "MRP",
      "Edit",
      "Delete",
    ],
    []
  );
  const delTest = (testId) => {
    deleteMedicine(testId)
      .then((res) => setMedicinesFn())
      .catch((err) => console.log(err));
  };
  return (
    <Table headings={headings}>
      {datas?.map((data, index) => (
        <tr key={index}>
          <td className="px-6 py-4 whitespace-nowrap">
            <div className="flex items-center">
              <div className="flex-shrink-0 h-10 w-10">
                <img
                  className="h-10 w-10 rounded-full object-cover"
                  src={data?.med_pic}
                  alt="medicine_image"
                />
              </div>
              <div className="ml-4">
                <div className="text-sm font-medium text-gray-900">
                  {data?.med_name}
                </div>
              </div>
            </div>
          </td>
          <td className="px-6 py-4 whitespace-nowrap">
            <div className="text-sm text-gray-900 capitalize">
              {data?.category?.pharmacy_category_name}
            </div>
          </td>
          <td className="px-6 py-4 whitespace-nowrap">
            <div className="text-sm text-gray-900 capitalize">
              {data?.quantity}
            </div>
          </td>
          <td className="px-6 py-4 whitespace-nowrap">
            <div className="text-sm text-gray-900 capitalize">
              {data?.manufacture_expire_date}
            </div>
          </td>
          <td className="px-6 py-4 whitespace-nowrap">
            <div className="text-sm text-gray-900 capitalize">
              {data?.selling_price}
            </div>
          </td>
          <td className="px-6 py-4 whitespace-nowrap">
            <div className="text-sm text-gray-900 capitalize">
              {data?.discount}
            </div>
          </td>
          <td className="px-6 py-4 whitespace-nowrap">
            <div className="text-sm text-gray-900 capitalize">{data?.mrp}</div>
          </td>
          <td className="px-6 py-4 whitespace-nowrap">
            <div
              className="text-sm text-gray-900 capitalize cursor-pointer"
              onClick={() => {
                setEditData(data);
                setOpen(true);
              }}
            >
              <TbUserEdit />
            </div>
          </td>
          <td className="px-6 py-4 whitespace-nowrap">
            <div
              className="text-sm text-gray-900 capitalize cursor-pointer"
              onClick={() => {
                delTest(data?._id);
              }}
            >
              <MdOutlineDeleteOutline color="red" />
            </div>
          </td>
        </tr>
      ))}
    </Table>
  );
};

export default MedicinesTable;
