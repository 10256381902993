import { axiosAuthorized } from "../../api/config";

export const getMedicinesCount = async () => {
  try {
    const repsonse = await axiosAuthorized.get(`/counttotalmedicine`);
    return repsonse;
  } catch (error) {
    throw error;
  }
}


export const getMedicines = async (pharmacyId) => {
    try {
        const response = await axiosAuthorized.get(`/medPhram/get_medPhram`);
        return response;
    } catch (error) {
        throw error;
    }
} 

export const addMedicine = async (initialState,category, image,pharmacyId, medicineId = null) => {
    try {
        const requestData = {
            ...initialState,
            category,
            med_pic: image
        };
        const requestConfig = {
            headers: { "Content-Type": "multipart/form-data" },
        };

        const url = medicineId
            ? `/medicine/update_medicine/${medicineId}`
            : `/medphram/add_medPhram`;

        const response = await axiosAuthorized[medicineId ? 'put' : 'post'](url, requestData, requestConfig);
        return response;
    } catch (error) {
        throw error;
    }
}

export const deleteMedicine = async (medicineId) => {
    try {
        const response = await axiosAuthorized.delete(`/medphram/delete_medPhram/${medicineId}`);
        return response;
    } catch (error) {
        throw error;
    }
}