import React, { useEffect, useState } from 'react';
import { ImageInput, NumberInput, SelectInput, ShowImage, TextAreaInput, TextInput } from '../Common/FormComponents';
import { GrFormClose } from 'react-icons/gr';
import { useForm } from '../../hooks';
import { addMedicine } from '../../utils/medicinesUtils/medicineUtils';
import { validateMedicineForm } from '../../utils/medicinesUtils/validateMedicineForm';
import { getCategories } from '../../utils/categoriesUtils/categoriesUtils';

const MedicineModal = ({ setOpen, editData = null, setEditData, setMedicinesFn ,user}) => {
  const medicineInitialState = {
    benefits: editData?.benefits || "",
    discount: editData?.discount || 0,
    manufacture_expire_date: editData?.manufacture_expire_date || "",
    med_description: editData?.med_description || "",
    med_name: editData?.med_name || "",
    mrp: editData?.mrp || 0,
    quantity: editData?.quantity || "",
    category: editData?.category?.pharmacy_category_name || "Select Category",
    brand: editData?.brand || "",
    baby_weight: editData?.baby_weight || 0,
    closure_type: editData?.closure_type || "",
    age_group: editData?.age_group || "",
    meta_name: editData?.meta_name || "",
    meta_description: editData?.meta_description || "",
  };
  const [med_pic, setMedPic] = useState(editData?.med_pic||null);
  const [medFormData, handleMedData, clearForm] = useForm(medicineInitialState);
  const [categories, setCategories] = useState([]);
  const [cats, setCats] = useState([]);
  const [catId, setCatId] = useState(0);
  useEffect(() => {
    getCategories()
      .then((res) => {
        setCategories([
          { pharmacy_category_name: "Select Category", _id: 0 },
          ...res.data.data,
        ]);
        setCats([
          "Select Category",
          ...res.data.data.map((item) => item.pharmacy_category_name),
        ]);
      })
      .catch((err) => {
        setCategories([{ pharmacy_category_name: "Select Category", _id: 0 }]);
      });
  }, []);
  useEffect(() => {
    const foundCategory = categories.find(
      (cat) => cat.pharmacy_category_name === medFormData.category
    );
    if (foundCategory) {
      setCatId(foundCategory._id);
    }
  }, [categories, medFormData.category]);
  const [loading,setLoading] = useState(false);
  const [err, setErr] = useState({ global: "" });
  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateMedicineForm(medFormData,catId, med_pic, setErr)) {
      setLoading(true);
      addMedicine(medFormData, catId,med_pic,user?._id, editData?._id)
      .then(res => { setMedicinesFn(); setEditData(null); setMedPic(null); clearForm(); setOpen(false) })
      .catch(err => console.log(err))
      .finally(()=>setLoading(false));
    }
  }
  return (
    <div className="absolute flex w-full h-screen top-0 left-0 bottom-0 justify-center items-center p-4 ">
      <div className="flex bg-white w-full max-w-screen-md overflow-y-auto h-full border shadow-lg  rounded-md gap-4 px-4 pb-4 pt-2 flex-col">
        <div className="flex w-full h-fit items-center justify-between font-semibold">
            <span>{"Add New Appointment"}</span>
          <span className="cursor-pointer" onClick={() => { setOpen(false); setEditData(null); }}><GrFormClose fontSize={16}/></span>
        </div>
        <div className="flex w-full h-fit justify-between gap-5">
            <TextInput name='Medicine Name' formName={"med_name"} data={medFormData} handleInput={handleMedData} setError={setErr}/>
            <SelectInput items={cats} name='Category' formName={"category"} data={medFormData} handleInput={handleMedData} setError={setErr}/>
        </div>
        <div className="flex w-full h-fit justify-between gap-5">
            <TextInput name='Manufactor - Exprire Date' formName={"manufacture_expire_date"} data={medFormData} handleInput={handleMedData} setError={setErr}/>
            <TextInput name='Quantity' formName={"quantity"} data={medFormData} handleInput={handleMedData} setError={setErr}/>
        </div>
        <div className="flex w-full h-fit justify-start gap-5">
            <NumberInput name={"MRP"} formName={'mrp'} data={medFormData} handleInput={handleMedData} setError={setErr}/>
            <NumberInput name={"Discount"} formName={'discount'} data={medFormData} handleInput={handleMedData} setError={setErr}/>
        </div>
        <div className="flex w-full h-fit justify-between gap-5">
            <TextAreaInput name={"Benefits"} formName={'benefits'} data={medFormData} handleInput={handleMedData} setError={setErr}/>
            <TextAreaInput name={"Description"} formName={'med_description'} data={medFormData} handleInput={handleMedData} setError={setErr}/>
        </div>
        <div className="flex w-full h-fit justify-between gap-5">
            <TextInput name='Brand' formName={"brand"} data={medFormData} handleInput={handleMedData} setError={setErr}/>
            <NumberInput name='Baby Weight' formName={"baby_weight"} data={medFormData} handleInput={handleMedData} setError={setErr}/>
        </div>
        <div className="flex w-full h-fit justify-between gap-5">
            <TextInput name='Closure Type' formName={"closure_type"} data={medFormData} handleInput={handleMedData} setError={setErr}/>
            <TextInput name='Age Group' formName={"age_group"} data={medFormData} handleInput={handleMedData} setError={setErr}/>
        </div>
        <div className="flex w-full h-fit justify-between gap-5">
            <TextInput hide={true} name='Meta Name' formName={"meta_name"} data={medFormData} handleInput={handleMedData} setError={setErr}/>
        </div>
        <div className="flex w-full h-fit justify-between gap-5">
            <TextAreaInput isHide={true} name='Meta Description' formName={"meta_description"} data={medFormData} handleInput={handleMedData} setError={setErr}/>
        </div>
        <div className="flex w-full h-fit justify-between gap-5">
            <ShowImage image={med_pic} setImage={setMedPic}/>
            <ImageInput imageName={"Medicine Image"} setImage={setMedPic}/>
        </div>
        {err.global && (<div className="flex text-sm text-red-600 w-full h-fit justify-center items-center font-semibold">{err.global}</div>)}
        <div className="flex w-full h-fit justify-between gap-5">
          <div className=" flex flex-col w-full gap-0.5 ">
            <button className="w-full border border-primary py-1 rounded-md  bg-white text-primary text-shadow-black flex justify-center items-center" onClick={() => { setOpen(false); setEditData(null); }}>
              Cancel
            </button>
          </div>
          <div className="flex flex-col w-full gap-0.5 ">
            <button className="w-full border bg-primary py-1 rounded-md  text-white border-primary text-shadow-black flex justify-center  items-center " disabled={loading} onClick={(e) => {handleSubmit(e);}}>
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MedicineModal;