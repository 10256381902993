export const validateMedicineForm = (medicineFormData,categoryId,image, setErr) => {
    const amountPattern = /^\d+(\.\d{1,2})?$/;

    if (!medicineFormData.med_name.trim().length) {
        setErr({ global: "Invalid Medicine Name" });
        return false;
    }

    if (categoryId === 0) {
        setErr({ global: "Please Select Category" });
        return false;
    }

    if (!medicineFormData.manufacture_expire_date.trim().length) {
        setErr({ global: "Invalid Manufactor - Expire Validity" });
        return false;
    }

    if (!medicineFormData.quantity.trim().length) {
        setErr({ global: "Invalid Quantity" });
        return false;
    }
    
    if (!amountPattern.test(medicineFormData.mrp)) {
        setErr({ global: "Invalid MRP" });
        return false;
    }

    if (!amountPattern.test(medicineFormData.discount)) {
        setErr({ global: "Invalid Discount" });
        return false;
    }
    
    if (!medicineFormData.benefits.trim().length) {
        setErr({ global: "Invalid Benefits" });
        return false;
    }
    
    if (!medicineFormData.med_description.trim().length) {
        setErr({ global: "Invalid Description" });
        return false;
    }
    if (!medicineFormData.brand.trim().length) {
        setErr({ global: "Invalid Brand" });
        return false;
    }
    if (!medicineFormData.closure_type.trim().length) {
        setErr({ global: "Invalid Closure Type" });
        return false;
    }
    if (!medicineFormData.age_group.trim().length) {
        setErr({ global: "Invalid Age Group" });
        return false;
    }
    return true;
};
